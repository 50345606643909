const Reviews = [
    {
        username:"Georgie Smith",
        description:"I have been collaborating with Sabir on many design projects over the last 5 years. I am a perfectionist with graphic design and Sabir and me have always",
        profile:require('../../../assets/images/Georgie-Smith.png'),
    },
    {
        username:"Made Santika",
        description:"I have problem with my ecommerce site, and i ask this guy to fix, its awesome support, thank you very much",
        profile:require('../../../assets/images/Made-Santika.png'),
    },
    {
        username:"Sofia Badyari",
        description:"Very efficient management and services. Very helpful and clear",
        profile:require('../../../assets/images/Sofia-Badyari.png'),
    },
    {
        username:"Huma Kiyani",
        description:"They are really helpful 😁 I appreciate them😊",
        profile:require('../../../assets/images/default-profile-img.png'),
    },
    {
        username:"Ritesh Madhok",
        description:"Superb team which is very helpful and totally dedicated to clients. We got our issue sorted in matter of hours and that too at a very economical rates. Highly recommended for all your Wordpress related stuff and much more.",
        profile:require('../../../assets/images/Ritesh-Madhok.png'),
    },
    {
        username:"Lucia B",
        description:"Very good service! Very helpful how I was guided in installing the application. If you have any doubt about installing the app, the author will definitely help you.",
        profile:require('../../../assets/images/default-profile-img.png'),
    },
    {
        username:"Jacob Paulsen",
        description:"Very impressed with the plugin I purchased and the support I received when I had a small issue.",
        profile:require('../../../assets/images/Jacob-Paulsen.png'),
    },
    {
        username:"Writs Law",
        description:"Great developers to work with. Thorough professionals.",
        profile:require('../../../assets/images/default-profile-img.png'),
    },
    {
        username:"Huma Kiyani",
        description:"They are really helpful 😁 I appreciate them😊",
        profile:require('../../../assets/images/default-profile-img.png'),
    },
    {
        username:"Mizanur Rahaman Mizan",
        description:"Good firm",
        profile:require('../../../assets/images/Mizanur-Rahaman-Mizan.png'),
    },
    {
        username:"Farooq Shaikh",
        description:"Good one",
        profile:require('../../../assets/images/Farooq-Shaikh.png'),
    },
    {
        username:"sandeep chawaria",
        description:"Nice",
        profile:require('../../../assets/images/sandeep-chawaria.png'),
    },
    {
        username:"Zuheb Zuheb",
        description:"Good👍👍",
        profile:require('../../../assets/images/Zuheb-Zuheb.png'),
    }
]

export default Reviews;