import React from "react";
import {Link} from 'react-router-dom';

const Wordpress = () => {
    return(
        <>
        <section className="wordpress-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="wordpress-inner-wrp">
                            <h2 className="common-title" data-aos="fade-up" data-aos-duration="1500">Wordpress</h2>
                            <p  data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Welcome to our WordPress Development Services! Whether you're a freelancer looking to create your own website or you need a professional WordPress site to showcase your skills, we are here to help. Our expertise in WordPress development allows us to create high-quality, custom websites that not only look great but also function seamlessly.</p>
                            <Link to="/contact-us/" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="primary-btn">Get a Qoute</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Wordpress;